.link {
  align-items: center;
  color: var(--color-midnight-900);
  display: flex;
  font-size: var(--font-size-m);
  font-weight: var(--font-weight-bold);
  line-height: var(--line-height-l);
  padding: 1.6rem 0;
  text-decoration: none;

  &:hover,
  &:focus {
    text-decoration: underline;
  }

  &:not(:last-child) {
    border-bottom: solid 0.1rem var(--color-rolling-stone-200);
  }

  &:first-child {
    padding-top: 0;
  }

  @media (--viewport-m) {
    font-size: var(--font-size-l);
  }

  & svg {
    fill: var(--linklist-chevron-color, var(--color-riptide-300));
    height: 2.8rem;
    transform: rotate(-90deg);
    width: 2.8rem;
  }

  &.inverted {
    color: var(--color-white);
  }
}
