.iframe { 
  aspect-ratio: 16/9;
  border-radius: var(--border-radius-l);
  border: 0;
  width: 100%;
}

.fourBy3 {
  aspect-ratio: 4/3;
}

.square {
  aspect-ratio: 1;
}