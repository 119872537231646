.container {
  gap: 2.4rem;
  position: relative;
  
  @media(--viewport-l){
    display: grid;
    grid-template-columns: 28rem 1fr;
    grid-template-rows: 1fr;
    gap: 3.2rem;
  }
}

.titleContainer {
  @media(--viewport-l){
    max-width: 50%;
  }
}

.carouselButtonsContainer {
  height: 100%;
  list-style: none;
  margin: 0;
  padding-left: 0;
  gap: 2.4rem;
  position: relative;

  & > *:not(:last-child){
    border-bottom: 1px solid var(--color-rolling-stone-200, #C3C9CC);
  }

  & .hideBorder {
    /* stylelint-disable-next-line scale-unlimited/declaration-strict-value */
    border-bottom-color: transparent;
  }

  @media (--viewport-l) {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    
    & > *:not(:last-child){
      border-bottom: none;
    }
  }
}

.verticalSliderContainer {
  height: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: 1fr;
  overflow: hidden;

  & .slideContainer {
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
    

    &.active {
      opacity: 1;
    }

    @media (--viewport-l) {
      & > * {
        height: 100%;
      }

      &:not(.active) {
        position: absolute;
        left: 31.2rem; /* (28rem + 3.2rem) */
        right: 0;
      }
    }
  }

  & a.slideContainer {
    cursor: pointer;
  }
}

.carouselButton {
  --icon-size: 2.8rem;
  --icon-rotation: -180deg;

  align-items: center;
  background: none;
  border: none;
  color: var(--vertical-carousel-button-color-dark, var(--color-midnight-800));
  cursor: pointer;
  display: flex;
  font-size: var(--font-size-2xl);
  font-weight: var(--vertical-carousel-font-weight-bold, var(--font-weight-bold));
  gap: 0.8rem;
  line-height: var(--line-height-l);
  padding: .8rem 0;
  text-align: left;
  width: 100%;

  & span {
    overflow-x:scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  & svg {
    flex: 0 0 auto;
    visibility: hidden;
    fill: currentcolor;
    height: var(--icon-size);
    transform: rotate(var(--icon-rotation));
    transition: transform 0.2s;
    width: var(--icon-size);
    margin: 0;
  }

  @media(--viewport-l){
    font-size: var(--font-size-3xl);
    padding: 0;
  }

  &.lightText {
    color: var(--vertical-carousel-button-color-light, var(--color-rolling-stone-050));
  }

  &.active{
    color: var(--heading-strong-accent-color);
    display: flex;
    align-items: center;
    border-bottom: none;
    
    & svg {
      visibility: visible;
    }
  }
}

.slideInnerContainer {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  position: absolute;
  left: 3.2rem;
  right: 4rem;
  bottom: 3.2rem;

  & h3 {
    font-size: var(--font-size-3xl);
    font-weight: var(--vertical-carousel-font-weight-bold, var(--font-weight-bold));
  }

  @media (--viewport-l) {
    padding: 0;
    text-align: left;
  }
}

.buttonLink {
  text-decoration: none;
}

.buttonInner {
  --icon-rotation: -180deg;
  --icon-size: 2.8rem;
  --gap: 0;

  align-items: center;
  /* stylelint-disable-next-line scale-unlimited/declaration-strict-value */
  color: var(--link-with-chevron-color, inherit);
  cursor: pointer;
  display: flex;
  gap: var(--gap);
  font-size: var(--font-size-m);
  font-weight: var(--button-basic-font-weight, var(--font-weight-bold));
  text-decoration: none;

  &:hover svg {
    transform: translateX(0.5rem) rotate(var(--icon-rotation));
  }

  & svg {
    margin-left: .6rem;
    fill: currentcolor;
    height: var(--icon-size);
    transform: rotate(var(--icon-rotation));
    transition: transform 0.2s;
    width: var(--icon-size);
  }
}



