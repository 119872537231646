.container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  & svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;

    /* height of svg and position should be slightly smaller than the container to fully cover the image */
    height: 99%;
    transform: translateY(0.1%);
  }

  & [class*="videoThumbnail"] picture {
    height: 100%;
  }
}

.children {
  mask-image:
    url(
      "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGZpbGw9Im5vbmUiIHZpZXdCb3g9IjAgMCA1OTIgNTkyIj4KICA8cGF0aCBmaWxsPSIjMDAwIiBkPSJNMjgzLjc0OSA1NzIuNDExYzQuMTE2IDMuMDIyIDEwLjc4OCAzLjAyMiAxNC45MTIgMGwyNzguNjEyLTIwNC4yODdjNC4xMTUtMy4wMjIgNi4xODEtOS40MjMgNC42MDctMTQuMzEzTDQ3NS40NTcgMjMuMjc4Yy0xLjU3NC00Ljg4Mi02Ljk3Mi04Ljg0Mi0xMi4wNjMtOC44NDJIMTE5LjAwOGMtNS4wOTEgMC0xMC40ODkgMy45Ni0xMi4wNjMgOC44NDJMLjUzIDM1My44MTljLTEuNTc0IDQuODgyLjQ5MSAxMS4yOSA0LjYwNyAxNC4zMTNsMjc4LjYxMiAyMDQuMjg2di0uMDA3WiIvPgo8L3N2Zz4K"
    );
  mask-position: center;
  mask-repeat: no-repeat;
  mask-size: 100%;
}
