.container {
  --gradient-hardness: 70%;
  --gradient-degree: 180deg;

  position: relative;
  overflow: hidden;
  display: flex;
  width: 100%;

  @media (--viewport-s) {
    --gradient-degree: 90deg;

    align-items: center;
    min-height: 40rem;
  }

  & > *:not(.imageHolder) {
    z-index: 1;
  }

  &.alignRight {
    align-items: flex-end;

    @media (--viewport-m) {
      --gradient-degree: 270deg;

      align-items: center;

      & .contentHolder {
        margin-left: 20%;
      }
    }

    @media (--viewport-l) {
      & .contentHolder {
        margin-left: 49%;
        max-width: 50%;
      }
    }
  }
}

.imageHolder {
  position: absolute;
  width: 100%;
  height: 100%;
  inset: 0;
  z-index: 0;
  overflow: hidden;
  border-radius: 0;

  &.hasRadius {
    border-radius: var(--default-picture-sourceset-border-radius);
  }

  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    inset: 0;
    background:
      linear-gradient(
        var(--gradient-degree, 90deg),
        rgb(0 0 0 / var(--gradient-hardness, 50%)) 0%,
        rgb(0 0 0 / 0%) 150%
      );
  }

  & > picture {
    width: 100%;
    height: 100%;
    border-radius: 0;

    & > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.contentHolder {
  padding: 3.2rem 2.4rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
  color: var(--color-white);
  aspect-ratio: 1;

  & p a,
  & a {
    color: var(--color-white) !important;
  }

  @media (--viewport-s) {
    aspect-ratio: auto;
  }

  @media (--viewport-m) {
    max-width: 80%;
    padding: 6rem 3.2rem;
  }

  @media (--viewport-l) {
    max-width: 55%;
    padding: 10rem 3.2rem;
  }

  &.contentFullWidth {
    @media (--viewport-m) {
      max-width: 100%;
      padding: 6rem 3.2rem;
    }
  
    @media (--viewport-l) {
      max-width: 100%;
      padding: 10rem 3.2rem;
    }
  }

  &.smallGap {
    gap: 1.6rem;
  }
}
