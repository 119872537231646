.container {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 2.4rem;

  @media (--viewport-m) {
    flex-direction: row;
    gap: 3.2rem;

    &:not(.noAutoFill) > * {
      width: 50%;
    }
  }
}

.reversed {
  @media (--viewport-m) {
    flex-direction: row-reverse;
  }
}

.mobileReversed {
  flex-direction: column-reverse;

  @media (--viewport-m) {
    flex-direction: row;
  }

  &.reversed {
    @media (--viewport-m) {
      flex-direction: row-reverse;
    }
  }
}

.alignCenter {
  @media (--viewport-m) {
    align-items: center;
  }
}

.alignEnd {
  @media (--viewport-m) {
    align-items: end;
  }
}

.wrap {
  flex-wrap: wrap;
}
