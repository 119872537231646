.formFields {
  display: grid;
  gap: 0.8rem 1.6rem;
  grid-template-columns: 1fr;

  & > *:last-child {
    grid-column: 1 / -1;
  }

  @media (--viewport-m) {
    grid-template-columns: 1fr 1fr;
  }
}

.checkbox{
  align-items: flex-start;
  color: var(--contactform-accept-conditions-color, var(--color-rolling-stone-600));
  display: flex;
  font-size: var(--font-size-s);
  gap: 0.8rem;
  margin-bottom: 0.8rem;

  & input {
    flex-shrink: 0;
    cursor: pointer;
  }

  & label {
    cursor: pointer;
  }

  & a {
    color: var(--contactform-accept-conditions-color, var(--color-rolling-stone-600));
  }
}

.error {
  color: var(--form-error-color, var(--color-pomegranate-300));
  font-size: var(--font-size-s);
  font-weight: var(--font-weight-regular);
  line-height: var(--line-height-m);
  margin: 0.8rem 0 0;
}