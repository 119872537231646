.container {
  width: 100%;
  background-color: var(--color-rolling-stone-050);
  display: flex;
  aspect-ratio: 1;
  flex-direction: column;
  align-items: flex-start;
  padding: 2.4rem;
  border-radius: var(--default-border-radius, var(--border-radius-l));
  overflow: hidden;
  text-decoration: none;
  color: var(--color-white);
  position: relative;

  & > * {
    position: relative;
    z-index: 2;
  }

  &::before {
    --height: 50%;

    content: "";
    display: block;
    z-index: 1;
    position: absolute;
    inset: 0 0 auto;
    height: var(--height, 50%);
    pointer-events: none;
    opacity: 0.6;
    transition: height 0.8s ease, opacity 0.3s ease;
    background:
      linear-gradient(
        0deg,
        rgb(0 0 0 / 0%),
        #242628
      );
  }

  & > picture {
    position: absolute;
    inset: 0;
    z-index: 0;

    & > img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      pointer-events: none;
      transition: transform 0.3s ease;
    }
  }

  &.hasLink:hover,
  &.hasLink:focus {
    &::before {
      --height: 80%;

      opacity: 0.7;
    }

    & > picture > img {
      transform: scale(1.05);
    }
  }
}

.title,
.title:is(h1, h2, h3, h4, h5) {
  font-size: var(--font-size-2xl);
  font-weight: var(--heading-weight);
}

.subText {
  font-size: var(--font-size-s);
  font-weight: var(--heading-weight);
}

.richText {
  display: flex;
  flex-direction: column-reverse;

  & > *:not(:last-child),
  & > :is(h1, h2, h3, h4, h5, p):not(:last-child) {
    margin-bottom: 0;
  }
}
