.childrenContainer {
  margin-top: 2.4rem;
}

.mediaContainer {
  border-radius: var(--default-border-radius, var(--border-radius-l));
  overflow: hidden;
}

.squareVideoContainer {
  aspect-ratio: 1;

  & * {
    aspect-ratio: 1;
  }
}
