.form {
  display: flex;
  flex-direction: column;

  & > :not(:last-child) {
    margin-bottom: var(--form-item-margin-bottom, 1.2rem);
  }

  & button {
    align-self: flex-start;
  }
}

.error {
  color: var(--form-error-color, var(--color-pomegranate-300));
  font-size: var(--font-size-m);
  line-height: var(--line-height-m);
}


.buttonAndLoader {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
}