.container {
  position: relative;
  padding: 0 var(--max-width-padding-s);

  @media (--viewport-m) {
    padding: 0;

    &:not(.reversed) .imageContainer picture {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}

.reversed {
  @media (--viewport-l) {
    margin-left: max(0px, calc((var(--container-l) - 100vw) / 2 * -1));
  }

  & .imageContainer {
    justify-content: flex-end;

    @media (--viewport-m) {
      & picture {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }

  & .card {
    left: 0;
    right: auto;
  }
}

.imageContainer {
  margin-bottom: 2.4rem;
  display: flex;

  @media (--viewport-m) {
    margin-bottom: 0;
  }

  & > * {
    width: 100%;

    @media (--viewport-m) {
      max-width: 90%;
    }
  }
}

.card {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  position: relative;
  z-index: 2;

  @media (--viewport-m) {
    background-color: var(--color-white);
    border-radius: var(--default-border-radius);
    color: var(--xl-image-card-text-color, var(--color-text));
    max-width: 50%;
    padding: 2.4rem var(--max-width-padding-m, 3.2rem);
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;

    &.top {
      top: 15%;
      transform: translateY(0%);
    }

    &.bottom {
      top: 85%;
      transform: translateY(-100%);
    }
  }

  @media (--viewport-l) {
    padding: var(--max-width-padding-m, 3.2rem);
  }

  @media (--viewport-xl) {
    padding: 4rem 4rem 4rem 3.2rem;
  }
}
