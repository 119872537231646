.container {
  display: flex;
  flex-direction: column;
  gap: 3.2rem;
  justify-content: space-around;

  @media (--viewport-m) {
    flex-direction: row;
  }
}

.itemContainer {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  flex: 1;
  padding: 0 3.2rem;

  @media (--viewport-m) {
    align-items: center;
    max-width: 33vw;
  }

  & svg {
    height: 2.4rem;
  }
}
