.grid {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  grid-template-areas: "title" "subtext" "modules";
  gap: 2.4rem;

  @media (--viewport-m) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-template-areas: "title subtext subtext" "title modules modules";
    gap: 3.2rem;
  }

  & > .titleContainer {
    grid-area: title;
  }

  & > .subTextContainer {
    grid-area: subtext;

    @media (--viewport-m) {
      & p { font-size: var(--font-size-2xl); }
    }
  }

  & > .moduleGrid {
    grid-area: modules;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(min(100%, 28rem), 1fr));
    gap: 2.4rem;
    margin-top: 0.8rem;

    @media (--viewport-m) {
      gap: 3.2rem;
    }
  }
}
