.inverted {
  & .button {
    color: var(--color-white);
  }

  & .buttonInner > svg {
    fill: var(--color-midnight-300);
  }
}

.item {
  padding: 1.6rem 0;

  &:not(:last-child) {
    border-bottom: solid 0.1rem var(--color-rolling-stone-200);
  }
}

.button {
  background: transparent;
  border: 0;
  color: var(--color-midnight-800);
  cursor: pointer;
  font-size: var(--font-size-m);
  font-weight: var(--font-weight-bold);
  line-height: var(--line-height-l);
  padding: 0;
  width: 100%;

  &:hover,
  &:focus {
    & .logo {
      background-color: var(--hover-color); /* This comes from the js */
    }
  }
}

.buttonInner {
  align-items: center;
  display: flex;
  justify-content: space-between;
  gap: 2rem;

  & > svg {
    fill: var(--color-midnight-900);
    height: 2.8rem;
    width: 2.8rem;
    transition: transform ease-out 200ms;
  }

  &.isOpen > svg {
    transform: rotate(180deg);
  }
}

.logoTitleContainer {
  display: flex;
  align-items: center;
  gap: 2rem;
  text-align: left;
}

.logo {
  align-items: center;
  background-color: var(--color-rolling-stone-300);
  border-radius: var(--border-radius);
  display: flex;
  height: 4rem;
  justify-content: center;
  transition: background-color 200ms ease-out;
  width: 4rem;

  & svg {
    fill: var(--color-white);
    height: auto;
    width: 2.4rem;
  }
}

.content {
  max-height: 0;
  overflow: hidden;
  padding: 0 0 0 6rem;
  transition: all ease-out 200ms;

  &.isOpen {
    max-height: none;
    padding: 1.6rem 0 1.6rem 6rem; /* 6 rem = 4rem icon + 2 rem gap */
  }

  &.noLeftPadding {
    padding-left: 0;
  }
}
