.container {
  @media (--viewport-m) {
    margin-top: -15rem;
  }
}

.title {
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-5xl);
  margin: 0;
  margin-bottom: 1.6rem;
}
