.grid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 2.4rem;
  
  @media (--viewport-s){
    grid-template-columns: repeat(2, 1fr);
  }

  @media (--viewport-m) {
    grid-template-columns: repeat(3, 1fr);
  }
  
  @media (--viewport-l) {
    grid-template-columns: repeat(4, 1fr);
    gap: 3.2rem;
  }
}

.person {
  background: transparent;
  border: 0;
  /* stylelint-disable-next-line scale-unlimited/declaration-strict-value */
  color: inherit;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  margin: 0;
  padding: 0;
  text-align: left;

  & .name {
    display: block;
    margin-top: 1rem;
    font-weight: var(--font-weight-bold);
  }

  & .jobTitle {
    opacity: 0.8;
  }
}

.modalDivider {
  background-color: var(--contactperson-header-border-color, var(--color-rolling-stone-100));
  height: 0.1rem;
  border: 0;
  margin: 2rem 0;
}

.buttonLinksContainer {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
}

.modalTitleName {
  margin-bottom: 0.1em !important;
}

.imageGridContainer {
  aspect-ratio: 1/1;
  width: 100%;
  
}

.imageContainer {
  aspect-ratio: 1/1;
  width: 100%;

  @media (--viewport-m) {
    aspect-ratio: 3/4;
  }
}