.container {
  box-shadow:
    rgb(0 0 0 / 0%) 0 0 0 0,
    rgb(0 0 0 / 0%) 0 0 0 0,
    rgb(0 0 0 / 10%) 0 0.4rem 0.6rem -0.1rem,
    rgb(0 0 0 / 10%) 0 0.2rem 0.4rem -0.2rem;
  background-color: var(--color-white);
  border-radius: var(--default-border-radius);
  padding: 4rem;
  border: 0.1rem solid var(--color-rolling-stone-200);

  & picture,
  & img {
    height: auto;
    max-width: 18rem;
    width: 100%;
  }

  & picture {
    margin: 0 0 1.2rem;
  }
}
