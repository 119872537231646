.container {
  background-color: var(--color-white);
  border-radius: var(--default-border-radius);
  display: flex;
  flex-direction: column;

  @media (--viewport-m) {
    align-items: stretch;
    flex-direction: row;
  }
}

.imageContainer {
  flex-shrink: 0;

  & picture {
    border-radius: 0;

    @media (--viewport-m) {
      height: 100%;
      position: relative;
      width: 34rem;

      & img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}

.contentContainer {
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  padding: 2.4rem;
  color: var(--call-to-action-content-color, var(--body-text-color));

  @media (--viewport-m) {
    padding: 4rem 2.4rem;
  }
}
