.label {
  --icon-rotation: -90deg;
  --icon-size: 2.8rem;
  --gap: 0;

  align-items: center;
  /* stylelint-disable-next-line scale-unlimited/declaration-strict-value */
  color: var(--link-with-chevron-color, inherit);
  cursor: pointer;
  display: flex;
  gap: var(--gap);
  font-size: var(--font-size-m);
  font-weight: var(--button-basic-font-weight, var(--font-weight-bold));
  text-decoration: none;

  &:hover svg {
    transform: translateX(0.5rem) rotate(var(--icon-rotation));
  }

  & svg {
    fill: var(--color-riptide-300);
    height: var(--icon-size);
    transform: rotate(var(--icon-rotation));
    transition: transform 0.2s;
    width: var(--icon-size);
  }

  &.hasSpacing {
    margin-top: 1rem;
  }

  &.renderArrow {
    --icon-rotation: 180deg;
    --icon-size: 2.2rem;
    --gap: 0.8rem;

    & svg {
      padding-bottom: 0.1rem;
      fill: currentcolor;
    }
  }
}
