.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-decoration: none;
  /* stylelint-disable-next-line scale-unlimited/declaration-strict-value */
  color: inherit;

  & > *:nth-child(2) {
    flex: 1;
  }

  &.hasLink:hover,
  &.hasLink:focus {
    & .title {
      text-decoration: underline;
    }

    & picture {
      &::before {
        --height: 80%;

        opacity: 0.2;
      }

      & > img {
        transform: scale(1.05);
      }
    }
  }
}

.imageHolder {
  width: 100%;

  & > picture {
    /* This overlay will give a nice effect on hover. */
    &::before {
      --height: 50%;

      content: "";
      display: block;
      z-index: 1;
      position: absolute;
      inset: 0 0 auto;
      height: var(--height, 50%);
      pointer-events: none;
      opacity: 0;
      transition: height 0.8s ease, opacity 0.3s ease;
      background:
        linear-gradient(
          0deg,
          rgb(0 0 0 / 0%),
          #242628
        );
    }
  }

  & img {
    transition: transform 0.3s ease;
  }

  &.hasInnerContentBox {
    &:not(:last-child) picture {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  &.landscapeCrop > picture {
    aspect-ratio: 16/10;
  }

  &.squareCrop > picture {
    aspect-ratio: 1;
  }
}

.contentHolder {
  --radius: var(--default-border-radius, 0.8rem);
  --padding-top: 1.6rem;
  --padding-right: 0rem;
  --padding: 1.6rem;

  display: flex;
  flex-direction: column;
  gap: 2.4rem;
  flex: 1;
  padding-top: var(--padding-top);
  padding-right: var(--padding-right);
  width: 100%;

  & > *:first-child {
    flex: 1;
  }

  @media (--viewport-s) {
    --padding-top: 2.8rem;
    --padding-right: var(--contentcard-contentholder-padding-right, 0rem);
    --padding: 2.4rem;
  }

  &.hasInnerContentBox {
    --padding-top: 2.4rem;

    padding: var(--padding-top) var(--padding) var(--padding) var(--padding);

    /* background-color: var(--block-container-background-color-light-gray, var(--color-rolling-stone-050));
    color: var(--block-container-surface-color-light-gray); */
    background-color: var(--contentcard-innerbox-background-color, var(--color-rolling-stone-050));
    color: var(--contentcard-innerbox-surface, var(--color-rolling-stone-900));

    @media (--viewport-l) {
      min-height: 11rem;
    }

    &:first-child {
      border-top-left-radius: var(--radius);
      border-top-right-radius: var(--radius);
    }

    &:last-child {
      border-bottom-left-radius: var(--radius);
      border-bottom-right-radius: var(--radius);
    }
  }

  &.hasSubTitle,
  &.hasInnerContentBox.hasSubTitle {
    --padding-top: 1.8rem;
  }
}

.title,
.title:is(h1, h2, h3, h4, h5, h6) {
  font-size: var(--font-size-l);
  font-weight: var(--heading-weight);

  @media (--viewport-s) {
    font-size: var(--font-size-2xl);
  }
}

.subTitle {
  color: var(--contentcard-subtitle-color);
  font-weight: var(--contentcard-subtitle-font-weight, 400);
  font-size: var(--contentcard-subtitle-font-size, var(--font-size-m));
  display: inline-block;

  & + .title:is(h1, h2, h3, h4, h5, h6):not(:last-child) {
    margin-bottom: 0.8rem;
  
  }
}

.fakeLink {
  --icon-rotation: -90deg;
  --icon-size: 2.8rem;
  --gap: 0;

  align-items: center;
  /* stylelint-disable-next-line scale-unlimited/declaration-strict-value */
  color: var(--link-with-chevron-color, inherit);
  cursor: pointer;
  display: flex;
  gap: var(--gap);
  font-size: var(--font-size-m);
  font-weight: var(--button-basic-font-weight, var(--font-weight-bold));
  text-decoration: none;

  &:hover svg {
    transform: translateX(0.5rem) rotate(var(--icon-rotation));
  }

  & svg {
    fill: var(--color-riptide-300);
    height: var(--icon-size);
    transform: rotate(var(--icon-rotation));
    transition: transform 0.2s;
    width: var(--icon-size);
  }

  &.renderArrow {
    --icon-rotation: 180deg;
    --icon-size: 2.2rem;
    --gap: 0.8rem;

    & svg {
      padding-bottom: 0.1rem;
      fill: currentcolor;
    }
  }
}

.lineClamp {
  --line-clamp: 4;

  display: -webkit-box;
  -webkit-line-clamp: var(--line-clamp, 4);
  -webkit-box-orient: vertical;
  overflow: hidden;
}
