.container {
  display: flex;
  flex-direction: column;
  row-gap: 4rem;
}

.textContainer {
  @media (--viewport-l) {
    max-width: 50%;
  }
}

.grid {
  --min-item-width: 28rem;
  --max-grid-cols: 3;

  display: grid;
  grid-gap: 4rem var(--default-grid-gap, 3.2rem);
  grid-template-columns: repeat(auto-fill, minmax(min(100%, var(--min-item-width)), 1fr));

  @media (--viewport-l) {
    grid-template-columns: repeat(var(--max-grid-cols), minmax(0, 1fr));
  }

  &.cols1 {
    --max-grid-cols: 1;
  }

  &.cols2 {
    --max-grid-cols: 2;
  }

  &.cols4 {
    --max-grid-cols: 4;
  }
}
